.legal-container {
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.legal-back {
    padding-top: 80px;
    text-align: center;
    font-family: Roboto-Regular;
}

.legal-title {
    font-size: var(--font-size-header);
    text-align: center;
    margin: unset;
    padding-bottom: 80px;
}

.legal-text-title {
    margin: unset;
}

.legal-text {
    margin-top: 10px;
    margin-bottom: unset;
    font-family: Roboto-Regular;
}
