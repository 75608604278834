:root {
  --font-size-header: 40px;
  --font-size-section: 32px;
  --font-size-title: 22px;
  --font-size-default: 18px;

  --shadow-distance: 3px;
  --border-radius: 5px;

  --color-background: #e6e6e6;
  --color-foreground: #ccc3bc;
  --color-box: #ffffff;
  --color-main: #000000;
  --color-secondary: #2f3c3f;
  --color-tint: #1a80ba;
}

* {
  text-decoration: none;
  transition: 0.25s;
}

p {
  margin: unset;
  color: var(--color-main);
  font-size: var(--font-size-default);
  font-family: Roboto;
}

a {
  margin: unset;
  color: var(--color-main);
  font-size: var(--font-size-default);
  font-family: Roboto;
  cursor: pointer;
}

button {
  border: unset;
  cursor: pointer;
  border-radius: var(--border-radius);
}

.border {
  box-shadow: calc(var(--shadow-distance) * -1) var(--shadow-distance) 0px rgb(from var(--color-tint) r g b / 25%);
  /* border: solid 2px var(--color-tint); */
}

.border.box {
  padding: 10px;
  background-color: var(--color-box);
}

.border.round {
  border-radius: var(--border-radius);
}

.gradient-text {
  background: -webkit-linear-gradient(0,#85c3ff,#cce5ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-window {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-background);
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

.project-window-box {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-top: 50px;
  flex-grow: 1;
}

.project-window-title-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-window-title {
  font-size: var(--font-size-section);
}

.project-links {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.project-button {
  padding: 10px 20px;
  border: unset;
  font-family: Roboto;
  color: white;
  background-color: black;
}

.project-footer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.project-footer-box {
  display: flex;
  bottom: 40px;
  justify-content: flex-start;
}

.close-button {
  background-color: unset;
  color: white;
  border: unset;
  font-size: 30px;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.content-layout {
  display: flex;
  flex-direction: column;
  gap: 220px;
}

.header {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  height: 100vh;
}

.header-layout {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  gap: 80px;
}

.header-child {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  width: 100%;
}

.header-img {
  width: 600px;
}

.header-title-parent {
  display: flex;
  gap: 20px;
}

.header-title {
  font-size: var(--font-size-header);
  color: var(--color-main);
}

.header-tagline {
  font-family: Roboto;
  font-size: var(--font-size-title);
  color: var(--color-secondary);
}

.header-connect-button {
  padding: 15px 30px;
  font-family: Roboto;
  font-size: var(--font-size-title);
  color: white;
  background-color: var(--color-tint);
  box-shadow: calc(var(--shadow-distance) * -1) var(--shadow-distance) 0px rgb(from var(--color-tint) r g b / 25%);
}

.project-link-button {
  padding: 10px 20px;
  font-size: var(--font-size-default);
}

.view-project-button {
  width: 100%;
  padding: 10px;
  font-size: var(--font-size-default);
  font-family: Roboto-Regular;
  background-color: var(--color-main);
  color: white;
}

.header-details-parent {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.header-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 40px;
}

.header-cta {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  text-align: center;
}

.header-details-key {
  color: white;
  font-family: 'Roboto';
  font-size: var(--font-size-header);
}

.header-details-values {
  display: flex;
  flex-direction: column;
}

.header-details-value {
  color: var(--color-secondary);
  font-family: 'Roboto-Regular';
}

.footer {
  text-align: center;
  padding-bottom: 120px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-link-parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.companies-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 64px;
  justify-content: space-between;
  justify-items: center;
}

.company-item {
  height: 60px;
  filter: brightness(0) opacity(0.6);
}

.text-bold {
  font-family: roboto;
  color: var(--color-main);
}

.text-typed {
  text-shadow: calc(-1 * var(--shadow-distance)) var(--shadow-distance) rgb(from var(--color-tint) r g b / 25%) !important;
  color: var(--color-tint);
}

.projects-title {
  margin-bottom: 5px;
  font-size: var(--font-size-section);
}

.projects-tagline {
  font-family: Roboto-Regular;
  margin-bottom: 50px;
}

.projects-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  gap: 50px 60px;
  justify-items: center;
}

.project-item {
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.project-img {
  height: 330px;
  width: 330px;
  background-size: contain;
  border-radius: var(--border-radius);
}

.project-title {
  font-size: var(--font-size-title);
  font-family: Roboto;
}

.project-description {
  text-align: left;
  color: var(--color-secondary);
  font-family: 'Roboto-Regular';
}

.project-screenshot {
  max-width: 400px;
  max-height: 400px;
  border-radius: var(--border-radius);
}

.project-skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.project-skill {
  font-size: var(--font-size-default);
  background-color: #53a8ca;
  color: black;
  padding: 4px 10px;
}

.profile-img {
  width: 140px;
  height: 140px;
  box-shadow: calc(var(--shadow-distance) * -1) var(--shadow-distance) 0px rgb(from var(--color-tint) r g b / 25%);
  border-radius: 120px;
  border: solid 2px var(--color-tint);
}

.skills-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.skill-item {
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.skill-item-basic {
  background-color: var(--color-background);
}

.skill-icon {
  width: 100px;
  height: 100px;
  border-radius: var(--border-radius);
}

.skill-texts {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.about-layout {
  display: flex;
  gap: 30px;
}

.about-title {
  font-family: 'Roboto';
  font-size: var(--font-size-title);
}

.about-text {
  font-family: 'Roboto-Regular';
  color: var(--color-secondary);
}

#contact-scroll {
  display: none;
}

#arrow-scroll {
  display: grid;
}

.contact-view {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  background-color: var(--color-background);
  color: var(--color-secondary);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  box-shadow: calc(var(--shadow-distance) * -1) var(--shadow-distance) 0px rgb(from var(--color-tint) r g b / 25%);
  border-bottom: solid 2px var(--color-tint);
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.contact-text {
  color: inherit;
  font-family: 'Roboto-Regular';
}

.link-icon-button {
  color: var(--color-main);
  cursor: pointer;
  padding: 10px;
  background-color: white;
  border-radius: 50px;
  font-size: var(--font-size-title);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-background);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-text {
  font-size: var(--font-size-header);
}

.no-scroll {
  overflow: hidden;
}

.hidden {
  scale: 0;
  visibility: hidden;
}

.circle-container {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 160px auto;
}

.center-image {
  position: absolute;
  width: 240px;
  height: 240px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.texts {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  position: absolute;
  transform: rotate(calc(var(--i) * 60deg)) translate(260px) rotate(calc(var(--i) * -60deg));
  padding: 10px 20px;
  background-color: var(--color-box);
  border-radius: var(--border-radius);
  font-size: var(--font-size-title);
  font-family: Roboto;
}

.text:before {
  content: attr(data-text);
  display: block;
  font-size: 14px;
  text-align: center;
}

.language-selection {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
}

.language-selection-item {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

.language-selection-main-item {
  background-color: var(--color-box);
  margin: 10px;
  margin-left: unset;
  padding: 3px;
}

.end-view {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (hover: hover) {
  button:hover {
    cursor: pointer;
    filter: brightness(120%);
  }

  .footer-link:hover {
    color: #ffbc21;
  }

  .contact-item:hover {
    filter: brightness(50%);
  }

  .link-icon-button:hover {
    filter: brightness(80%);
  }

  .project-button:hover, .view-project-button:hover {
    background-color: #3a3a3a;
  }

  .contact-item:hover {
    filter: brightness(70%);
  }
}

@media screen and (max-width: 1100px) {
  :root {
    --font-size-header: 26px;
    --font-size-section: 20px;
    --font-size-title: 16px;
    --font-size-default: 12px;
    --shadow-distance: 3px;
    --border-radius: 3px;
  }

  .skills-view {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .skill-item {
    gap: 10px;
  }

  .skill-icon {
    width: 50px;
    height: 50px;
  }

  .skill-texts {
    gap: 0px;
  }

  .container {
    gap: 20px;
  }

  .header-connect-button {
    padding: 10px 20px;
  }

  .profile-img {
    width: 140px;
    height: 140px;
  }

  .header-title-parent {
    flex-direction: column;
    gap: 0;
  }

  .header-details-parent {
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }

  .header-details {
    gap: 5px;
  }

  .header-details-value {
    max-width: fit-content;
  }

  .header-layout {
    padding-top: 40px;
    gap: 40px;
    justify-content: flex-start;
  }

  .header-child {
    flex-direction: column;
    gap: 50px;
  }

  .header-cta {
    gap: 10px;
  }

  .project-links {
    gap: 5px;
  }

  .project-button {
    padding: 5px 10px;
  }

  .contact-view {
    justify-content: center;
    gap: 10px;
    height: 40px;
  }

  .companies-layout {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }

  .company-item {
    height: 30px;
  }

  .content-layout {
    gap: 120px;
  }

  .text-bold {
    text-shadow: -3px 3px #ffffff40;
  }

  .projects-tagline {
    margin-bottom: 20px;
  }

  .projects-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 10px 0px;
    justify-items: center;
  }

  .project-window-box {
    gap: 30px;
  }

  .project-window-title-parent {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }

  .project-img {
    width: 160px;
    height: 160px;
  }

  .project-screenshot {
    max-width: 300px;
    max-height: 300px;
  }

  .about-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hidden {
    scale: 1;
    visibility: visible;
  }

  .profile-img {
    width: 80px;
    height: 80px;
  }

  .language-selection {
    gap: 0px;
  }

  .language-selection-item {
    width: 20px;
    height: 20px;
  }

  .language-selection-main-item {
    margin: 5px;
  }

  .project-item {
    gap: 10px;
  }

  .border.box {
    padding: 5px;
  }

  .end-view {
    gap: 20px;
  }
}
