.window.in {
    animation: scale-in 0.3s ease 1;
}

@keyframes scale-in {
    0% {
        opacity: 0;
        scale: 1.05;
    }
    100% {
        opacity: 1;
        scale: 1;
    }
}

.window.out {
    animation: scale-out 0.3s ease 1;
}

@keyframes scale-out {
    0% {
        opacity: 1;
        scale: 1;
    }
    100% {
        opacity: 0;
        scale: 1.05;
    }
}

.bounce {
    animation: bounce 1s infinite ease;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

.fade-in-element {
    animation: fade-in-element 0.6s ease 1;
}

.fade-in-element-fast {
    animation: fade-in-element 0.4s ease 1;
}

@keyframes fade-in-element {
    0% {
        transform: translateY(20px);
        scale: 1;
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        scale: 1;
        opacity: 1;
    }
}

@media screen and (max-width: 1100px) {
    .fade-in-element {
        animation: unset;
        scale: 1;
        opacity: 1;
    }
}